import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import palette from '@src/theme/palette';
import Config from '@src/utils/api/config';
import { useState } from 'react';

const { isPalmsPlay } = Config;

interface Props {
	options: any[];
}

const TableOfContents = (props: Props) => {
	const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

	return (
    <Card sx={{ 
			width: "100%", height: "100%", 
			px: {
				xl: "30px",
				lg: "30px",
				md: "10px",
				sm: "10px",
				xs: "10px",
			}, py: "15px", borderRadius: "25px" }}>
			{props.options.map((section: any, index: number) => {
				const active = expanded === index.toString();

				return (
					<Accordion key={index.toString()}
						expanded={active} onChange={handleChange(index.toString())}>
						<AccordionSummary
							expandIcon={active ? <RemoveOutlinedIcon sx={{ color: palette.blue["main"]}}/> : <AddOutlinedIcon sx={{ color: palette.blue["md-light"]}}/>}
							aria-controls="panel1-content"
							id="panel1-header"
						>
							<Typography 
								fontFamily="Inter"
								fontWeight={700}
								color={active ? palette.blue.main : palette.blue["md-light"]} >{section.title}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<ol style={{paddingLeft: "20px"}}>
								{section.steps.map((step: string, stepIndex: number) => (
									<li key={stepIndex}>
										<Typography fontSize={14}>
											{step}
										</Typography>
									</li>
								))}
							</ol>
						</AccordionDetails>
					</Accordion>
				);
			})}
    </Card>
  );
}

export default TableOfContents