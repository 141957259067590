// @mui
import { Card, Typography } from '@mui/material';

type ItemsProps = {
  title: string;
  value: string;
};

function Items({ title, value }: ItemsProps) {
  return (
    <Card sx={{
      py: 1,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      height: {
        xl: "120px",
        lg: "110px",
        md: "100px",
        sm: "120px",
        xs: "120px",
      },
      boxShadow: 5,
      alignItems: "center",
      justifyContent: "center",
      textAlign: 'center',
      color: "black",
      bgcolor: "white",
    }}
    >
      <Typography variant="h4">{value}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </Card>
  );
}

export default Items;
