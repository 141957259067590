/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import GameHistory from "../game-history";
import Body from './body';
import GameDetailsDialog from './game-details';
import Header from './header';
import GamesProvider from './provider';

export default function Games() {
  const [params] = useSearchParams();
  const code = params.get("code");

  return (
    <GamesProvider>
      {code ? 
        <GameHistory /> :
        <>
          <Helmet>
            <title> Games </title>
          </Helmet>
          <Header />
          <Body />
          <GameDetailsDialog />
        </>
      }
    </GamesProvider>
  );
}
