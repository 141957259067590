import { Container, Grid } from "@mui/material";
import SvgColor from '@src/components/svg-color';
import { AppWidgetSummary } from "@src/sections/@dashboard/app";
import { usePaymentsService } from "@src/store/hooks/usePaymentsService";
import palette from '@src/theme/palette';
import Config from '@src/utils/api/config';
import numeral from "numeral";
import { useNavigate } from "react-router-dom";

const { isPalmsPlay } = Config;

type Props = {
	onChangeScreen: (value: number) => void;
}

const Header = (props: Props) => {
  const navigate = useNavigate();
	const { totalPurchasesThisMonth } = usePaymentsService();

	const onHandlePress = (index: number) => {
		props.onChangeScreen(index);
		navigate("/dashboard/purchase")
	}

	return (
		<Container>
			<Grid container 
				spacing={{
					xl: 3,
					lg: 3,
					md: 3,
					sm: 1.5,
					xs: 1.5
				}}>
				<Grid item xs={12} sm={6} md={3.2} >
					<AppWidgetSummary
						onClick={() => onHandlePress(0)}
						title="Total Purchases this Month"
						value={totalPurchasesThisMonth?.count}
						color={"#16DBCC"}
						icon={<SvgColor src={`/assets/icons/ic_store.svg`} sx={{ width: 24, height: 24 }} />}
						/>
				</Grid>
				
				<Grid item xs={12} sm={6} md={3.2} >
					<AppWidgetSummary
						onClick={() => onHandlePress(1)}
						title={isPalmsPlay ? "Total Palms Points (month)" : "Total Amount of $ (month)"}
						value={numeral(totalPurchasesThisMonth?.totalAmount).format("0,000.00")}
						color={isPalmsPlay ? palette.orangeYellow.main : palette.green.main}
						icon={isPalmsPlay ? <SvgColor src={`/assets/icons/ic_palm_tree.svg`} sx={{ width: 24, height: 24 }} /> : "solar:dollar-bold"}
					/>
				</Grid>
			</Grid>
		</Container>
)
}

export default Header