import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';

import { GenerateProgramsAPIParams } from '@src/lib/types/programs';

import { useProgramsService } from './hooks';
import { useProgramsContext } from './provider';
import ProviderName from './provider-name';

const FilterSection = () => {
  const { handleSubmit, control } = useForm<GenerateProgramsAPIParams>();
  const { onSetFormValues } = useProgramsContext();
  const { isLoading } = useProgramsService();

  const onSubmit = (data: GenerateProgramsAPIParams) => {
    onSetFormValues({
      providerName: data.providerName,
      from_date: data.from_date ? moment(data.from_date).format("YYYY-MM-DD") : null,
      to_date: data.to_date ? moment(data.to_date).format("YYYY-MM-DD") : null,
    });
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            width: "100%",
            display: 'flex',
            flexDirection: {
              xl: 'row',
              lg: 'row',
              md: 'row',
              sm: 'column',
              xs: 'column',
            },
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 3,
            height: "auto",
            pt: 3,
            pb: 5,
          }}>
          <Controller
            name="providerName"
            control={control}
            render={({ field: { value, onChange } }) => (
              <ProviderName
                value={value}
                onChange={onChange}
              />
            )}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
            }}>
            <Controller
              name="from_date"
              control={control}
              render={({ field: { value, onChange } }) => (
                <MobileDatePicker
                  label="From"
                  value={value && new Date(value)}
                  sx={{ flex: 1 }}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      error: false,
                      InputProps: { style: { fontSize: "12px" } }
                    },
                  }}
                  onChange={(selectedDateValue) => onChange(selectedDateValue as string)}
                />
              )}
            />
            <Controller
              name="to_date"
              control={control}
              render={({ field: { value, onChange } }) => (
                <MobileDatePicker
                  label="To"
                  value={value && new Date(value)}
                  sx={{
                    flex: 1
                  }}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      error: false,
                      InputProps: { style: { 
                        fontSize: "12px",
                      } }
                    },
                  }}
                  onChange={(selectedDateValue) => onChange(selectedDateValue as string)}
                />
              )}
            />
            <LoadingButton
              type='submit'
              loading={isLoading}
              size="large"
              variant={"contained"}
              sx={{
                height: {
                  xl: 52,
                  lg: 52,
                  md: 50,
                  sm: 50,
                  xs: 50,
                },
                fontSize: {
                  xl: "11px",
                  lg: "11px",
                  md: "9px",
                  sm: "9px",
                  xs: "9px",
                },
                bgcolor: "purple.dark",
                "&:hover": {
                  bgcolor: "purple.main"
                }
              }}
            >
              GENERATE
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </LocalizationProvider>
  );
}

export default FilterSection;
