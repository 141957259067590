import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import Iconify from '@src/components/iconify';
import { useCodeGeneratorService } from '../hooks';

interface Props {
  url: string;
  copied: boolean;
  handleCopyLink: () => void;
}

function GenerateButton(props: Props) {
  const { onCreateCodeGenerator } = useCodeGeneratorService();
  const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  if(isMobileScreen){
    return (
      <Box>
        {onCreateCodeGenerator.isSuccess ? null : 
        <LoadingButton
          loading={onCreateCodeGenerator.isPending}
          type="submit"
          sx={{
            px: 5,
            width: "100%",
            borderRadius: "10px",
            color: "white",
            height: "50px",
            bgcolor: "#6A00AC"
          }}>
          GENERATE
        </LoadingButton>}
        <Stack 
          direction={{
            xl: "row",
            lg: "row",
            md: "row",
            sm: "column",
            xs: "column",
          }} gap="2rem"
          p="7px"
          alignItems={"center"} 
          width="100%"
          height={64}
          mt={2}
          bgcolor={"#B8E0FC"}
          borderRadius={"10px"}
          border="2px solid #72C5FF" >
          <Box 
            sx={{
              display: "flex", bgcolor: "white", width: "100%", 
              height: "50px", borderRadius: "10px", 
              pl: "26px", alignItems: "center",
              justifyContent: "space-between"
            }}>
            <Typography variant='body1' color="#B1B6BF">
              {props.url}
            </Typography>
          </Box>
  
        </Stack>
        <Button
          disabled={!props.url}
          onClick={props.handleCopyLink}
          startIcon={<Iconify icon="fluent-mdl2:copy" width={17} height={17} />}
          sx={{
            px: 8,
            mt: 2,
            width: "100%",
            borderRadius: "10px",
            color: "white",
            height: "50px",
            bgcolor: props.url ? "#0E39C1" : "#C3C3C3"
          }}>
          {props.copied ? "LINK COPIED!" : "COPY LINK"}
        </Button>
      </Box>
    );
  }

  return (
    <Stack direction={"row"} gap="2rem"
      px="17px"
      alignItems={"center"} 
      width="100%" height={79} 
      bgcolor={"#B8E0FC"} mt={2}
      borderRadius={"10px"}
      border="2px solid #72C5FF" >
      {onCreateCodeGenerator.isSuccess ? null : <LoadingButton
        loading={onCreateCodeGenerator.isPending}
        type="submit"
        sx={{
          px: 5,
          borderRadius: "10px",
          color: "white",
          height: "50px",
          bgcolor: "#6A00AC"
        }}>
        GENERATE
      </LoadingButton>}
      <Box 
        sx={{
          display: "flex", bgcolor: "white", width: "100%", 
          height: "50px", borderRadius: "10px", 
          pl: "26px", alignItems: "center",
          justifyContent: "space-between"
        }}>
        <Typography variant='body1' color="#B1B6BF">
          {props.url}
        </Typography>
        <Button
          disabled={!props.url}
          onClick={props.handleCopyLink}
          startIcon={<Iconify icon="fluent-mdl2:copy" width={17} height={17} />}
          sx={{
            px: 8,
            borderRadius: "10px",
            color: "white",
            height: "50px",
            bgcolor: props.url ? "#0E39C1" : "#C3C3C3"
          }}>
          {props.copied ? "LINK COPIED!" : "COPY LINK"}
        </Button>
      </Box>
    </Stack>
  )
  
}

export default GenerateButton;