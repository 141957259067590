import { Container } from '@mui/material';
import sections from '@src/lib/data/portal-guide.json';
import Config from '@src/utils/api/config';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import TableOfContents from './contents';
import Toolbar from './toolbar';

const { isPalmsPlay } = Config;

export default function PortalGuide() {
	const [search, setSearch] = useState<string>("");
	
	const newSections = useMemo(() => {
		if (!isPalmsPlay) return sections;

		return sections.filter((section) => {
			const { title } = section;
			return title.toLowerCase().includes(search.toLowerCase()) && title !== "How to Check Redeem Transactions and Update Redeem Status";
		});
	}, [search]);

	return (
		<>
			<Helmet>
				<title> Portal Guide </title>
			</Helmet>
			<Container>
				<Toolbar search={search} onChange={setSearch} />
				<TableOfContents options={newSections}/>
			</Container>
		</>
	)
}